
.twitch {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: auto;
    display: inline-flex;
    flex-direction: column;
  }
  
  .twitch > * {
    flex: 1;
  }
  
  .twitch-player {
    width: var(--vidSize);
    height: calc(var(--vidSize) / 16 * 9);
  }
  
  .twitch-link {
    margin-top: 1em;
}
  